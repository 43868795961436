import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay, Radio } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import { States, StatesList } from '@core/enums/USStates';
import { CALC_ERRORS } from '@core/models/CalculationError';
import { rxVariableReplace } from '@core/models/Content';
import { FOOTNOTE_FONT_RATIO } from '@core/models/DealStyle';
import Section from '@core/models/Section';
import User from '@core/models/User';
import Variable, { ValueType, VariableType } from '@core/models/Variable';
import { DateFormatter } from '@core/utils/DateTime';
import { dt } from '@core/utils/Environment';
import { getUniqueKey } from '@core/utils/Generators';
import { formatOrder } from '@core/utils/OrderFormatter';

import {
  Breakable,
  Button,
  DayTime,
  Dropdown,
  Form,
  Icon,
  Loader,
  MenuItem,
  Popover,
  Validator,
} from '@components/dmp';

import MultiselectDropdown from '@components/MultiselectDropdown';
import VariableConnector from '@components/connect/VariableConnector';
import DataSourceBrowser from '@components/deal/DataSourceBrowser';
import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

import ImageUploader from './ImageUploader';

@autoBindMethods
class VariableView extends Component {
  static defaultProps = {
    inline: false,
    noReplace: false,
    readonly: false,
    variable: null,
    style: null,
    lockSection: _.noop,
    unlockSection: _.noop,
    onImageLoaded: _.noop,
    markReview: false,
    editExtractedValue: false,
    recomputeHeight: _.noop,
  };

  static propTypes = {
    container: PropTypes.object,
    inline: PropTypes.bool,
    noReplace: PropTypes.bool,
    onSave: PropTypes.func,
    readonly: PropTypes.bool,
    section: PropTypes.instanceOf(Section),
    text: PropTypes.string.isRequired,
    user: PropTypes.instanceOf(User),
    variable: PropTypes.instanceOf(Variable),
    style: PropTypes.object,
    lock: PropTypes.object,
    lockSection: PropTypes.func,
    unlockSection: PropTypes.func,
    recomputeHeight: PropTypes.func,
    //These props are both specific to the AI review tab.
    markReview: PropTypes.bool,
    editExtractedValue: PropTypes.bool,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      // Whether popover is showing
      show: false,
      // Whether DataSourceBrowser is showing
      browseDS: false,
      // PROTECTED elements have 2 states. initial state masks value
      revealed: false,
      decrypted: '',
      loading: false, //loader
      value: '',
      dateValue: '',
      isValid: true,
      imageAttachment: null,
    };

    this.textRef = createRef();
    this.inputRef = null;

    // This is just for a base id for child controls
    this.id = 'var-view-' + getUniqueKey();
    // this.dayPickerInputRef = React.createRef();
  }

  populate(props) {
    let { variable } = props;
    let value = '';

    if (variable) {
      // If we're looking at a "derived" property like [#Date.month] or [#Days.spelled]
      // Point to the baseVariable (where value is stored) to populate state
      if (variable.isDerived) {
        variable = variable.baseVariable;
      }

      // For multiline vars, convert to string for editing
      if (variable.multiline && Array.isArray(variable.value)) {
        value = variable.value.join('\n');
      } else if (variable.valueType === ValueType.MULTI_SELECT) {
        value = variable.baseVariable.multiSelectedOptions;
      } else {
        value = variable.isRedacted ? 'Value redacted' : variable.value || '';
      }
    }

    this.setState({
      value,
      isValid: true,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, 'variable.value') !== _.get(prevProps, 'variable.value')) this.populate(this.props);
    if (this.props.variable?.isDerived) {
      if (this.props.variable?.baseVariable?.value !== prevProps.variable?.baseVariable?.value) {
        this.populate(this.props);
      }
    }
  }

  handleChange(e) {
    const { variable } = this.props;
    const { value } = e.target;

    if (variable.baseVariable.valueType === ValueType.DATE) {
      const isValid = this.validateDate(value);
      this.setState({ value, isValid });
    } else {
      this.setState({ value });
    }
  }

  handleKeyCommand(e) {
    //escape means cancel
    if (e.keyCode == 27) this.hide();
    //enter means save, but disable for multiline (to allow line breaks in textarea)
    else if (e.keyCode == 13 && !_.get(this.props, 'variable.baseVariable.multiline')) this.save();
  }

  async selectListItem(value) {
    const { variable, user, markReview, editExtractedValue } = this.props;

    // Update in state first so that UI doesn't appear to hang while var is saved (and Deal refreshes)
    await this.setState({ value, loading: true });

    if (markReview || editExtractedValue) return;

    await Fire.saveVariable(variable.deal, variable, value);
    this.hide();
    await Fire.addActivity(variable.deal, user, DealAction.VARIABLE, variable.name);
    this.pushConnectVariable(value);
  }

  selectMultilineValue(event) {
    const value = event.target.value;
    this.setState({ value });
  }

  async handleDSItems(ds, items, fields) {
    const { variable } = this.props;
    const deal = variable.deal;

    // We're looking at a collection item var (Table collection item selection is handled in TableView),
    // and picking a collection item to use to populate that var, as well as any others pulling from the same collection
    // so we need to update both the DealConnection with the collection item id,
    // and the corresponding variable values (which were just loaded via the DataSourceBrowser and passed here)
    const connection = _.find(deal.connections, { type: ds.connectType });
    const requiredDS = ds.requiredDS;
    const item = items.length === 1 ? items[0] : null;
    if (connection) {
      const json = connection.json;
      json.idFields[requiredDS] = item?._itemId || null;
      await Fire.saveDealConnections(deal.dealID, [json]);
    }
    // And fill (or clear) connected variables instantly since we've just fetched the values!
    const vars = {};
    _.forEach(fields, ({ sourceVariable, id }) => {
      vars[sourceVariable] = _.get(item, id, null);
    });
    if (_.keys(vars).length > 0) {
      await Fire.saveVariables(deal.dealID, vars);
    }

    // Finally, close the browser after all selected date has been applied
    this.setState({ browseDS: false });
  }

  onImageLoaded() {
    if (this._isMounted) this.props.recomputeHeight();
  }

  validateDate(value) {
    const { variable } = this.props;
    const isDate =
      variable?.baseVariable?.type == VariableType.SIMPLE && variable?.baseVariable?.valueType == ValueType.DATE;

    if (!isDate) return true;

    const isValid = value === '' || !isNaN(Date.parse(value));
    this.setState({ isValid });
    return isValid;
  }

  validate() {
    const { variable } = this.props;
    const { value } = this.state;

    if (variable?.baseVariable?.valueType === ValueType.DATE) {
      return this.validateDate(value);
    }

    return variable.validateValue(value);
  }

  async save(suppressOnSave) {
    const { value, decrypted, imageAttachment } = this.state;
    const { inline, onSave, variable, user, markReview, editExtractedValue, deal } = this.props;

    // We should re-validate since Validator has a debouce and users could still try to
    // save an invalid value by hitting return. Also this solidify the 'inline' version.
    const isValid = this.validate();

    //allow invalid values to be marked as reviewed and saved even if they are invalid.
    if (!isValid && !markReview) {
      return;
    }

    if (!inline) await this.setState({ loading: true });

    //Protected elements need to be saved via API because they get encrypted
    if (variable.type === VariableType.PROTECTED) {
      await this.setState({ revealed: false });
      await API.call('setProtected', { dealID: variable.deal.dealID, value: decrypted, varName: variable.name });
      //log SHARE_SECRET activity when editor saves PROTECTED var; use message field to store name of accessed var
      await Fire.addActivity(variable.deal, user, DealAction.SHARE_SECRET, variable.name);
      this.hide();
    }

    // Entering a yearless date should use the current year.
    if (variable?.baseVariable?.valueType === ValueType.DATE && !value.match(/\d{4}$/) && value !== '') {
      const currentYear = new Date().getFullYear();
      await this.setState({ value: `${value}/${currentYear}` });
    }

    //normal elements can save directly to firebase
    else {
      await this.setState({ value });

      // Save multiline default value as string array
      let saveValue = value;
      console.log('Check save value: ', saveValue);

      if (variable?.baseVariable?.valueType === ValueType.STRING && variable?.baseVariable?.multiline) {
        const valueList = Array.isArray(variable?.baseVariable?.value)
          ? variable?.baseVariable?.value
          : variable?.baseVariable?.value?.split('\n');

        const multilineValueOptions =
          !variable.multilineValueOptions && valueList?.length > 1
            ? valueList
            : variable?.baseVariable?.multilineValueOptions;

        saveValue = value ? value?.split('\n') : null;
        await Fire.saveVariable(variable.deal, variable, saveValue, multilineValueOptions);
      } else if (variable.baseVariable.valueType === ValueType.MULTI_SELECT) {
        saveValue = _.map(value, 'value').join('\n');
        await Fire.saveVariable(variable.deal, variable, saveValue);
      } else {
        if (variable.valueType === ValueType.IMAGE && !inline) {
          //update the value from the preview base64 value to the downloadurl and key.
          //we do this here because we do not want to save the attachment until confirmed.
          if (imageAttachment) {
            // If we have imageAttachment it is because we're just uploaded an image, let's save it.
            saveValue = await this.createImageAttachment(imageAttachment);
          } else if (!variable.value && !imageAttachment) {
            // If we have neither a value or an imageAttachment it means we've cleared the image, let's delete it.
            await this.removeImageAttachment();
          }
        }
        await Fire.saveVariable(variable.deal, variable, saveValue);
      }

      await Fire.addActivity(variable.deal, user, DealAction.VARIABLE, variable.name);

      this.pushConnectVariable(saveValue);

      //If a variable tied to a lens changes rerun lensCheck on that specific variable
      if (deal?.lensChecks) {
        const hasRelatedLens = !!_.find(deal.lensChecks.checks, ({ lens }) => {
          return lens.relatedVariable === variable.name;
        });
        if (hasRelatedLens) {
          try {
            await API.call('rerunLensVariableCheck', { dealID: deal.dealID, variableName: variable.name });
          } catch (err) {
            console.error(`Failed to queue lens check for deal ${deal.dealID}`);
          }
        }
      }

      if ((inline || markReview || editExtractedValue) && !suppressOnSave) {
        onSave(variable);
      } else {
        this.hide();
      }
    }
  }

  // If this Variable is connected to an external provider, push that update if configured to do so
  // And the check for external connection/autoPush is internal to this function,
  // so we can safely call this everywhere prior that Fire.saveVariable is called
  async pushConnectVariable(value) {
    const { variable } = this.props;

    if (variable.connection && variable.autoPush) {
      await API.call('setConnectVariable', {
        teamID: variable.deal.team,
        connection: variable.connection.json,
        variable: variable.json,
        value,
      });
    }
  }

  async show(e, canShow) {
    const { variable, lock } = this.props;
    const { revealed, loading } = this.state;

    if (canShow && !lock) {
      //this prevents summary sections from expanding
      //because click was intended to show variable
      e.stopPropagation();

      if (variable?.valueType === ValueType.IMAGE && variable.isRedacted) return;

      this.populate(this.props);

      //if we're looking at a protected variable and user is editor (assignee)
      //automatically call reveal so that user can edit decrypted data
      if (variable?.type === VariableType.PROTECTED && !revealed && !loading && this.canInspect) {
        this.revealProtected();
      }

      if (variable?.type === VariableType.CONNECTED && this.canEdit && variable.isMissingDS) {
        this.setState({ browseDS: true });
        return;
      }

      await this.setState({ show: true });
      this.focus();
    }
  }

  revealProtected() {
    const { variable, user } = this.props;

    this.setState({ loading: true });

    //only need api call if there's a value
    if (!variable.value) {
      this.setState({ revealed: true, loading: false, decrypted: '' });
      return;
    }

    API.call(
      'getProtected',
      { dealID: variable.deal.dealID, varName: variable.name },
      (decrypted) => {
        this.setState({ decrypted, revealed: true, loading: false });

        //log VIEW_SECRET activity when anyone accesses PROTECTED var; use message field to store name of accessed var
        Fire.addActivity(variable.deal, user, DealAction.VIEW_SECRET, variable.name);
      },
      () => {
        //expected error is thrown when user is party or signer, but deal is not signed yet
        //so user does not YET have access to the data
        //setting decrypted state var to variable.val will show masked value, which is intended behavior
        this.setState({ decrypted: variable.val, revealed: true, loading: false });
      }
    );
  }

  hide() {
    const { unlockSection, section } = this.props;

    if (this._isMounted) {
      this.setState({ show: false, revealed: false, loading: false, decrypted: '' });
      unlockSection(section.id);
    }
  }

  focus() {
    const { lockSection, section } = this.props;

    if (this.inputRef) {
      this.inputRef.focus();
      lockSection(section.id);
    }
  }

  // Prevent mouse events related to showing/hiding popovers from taking whole section into editing
  stop(e) {
    e.stopPropagation();
  }

  // TODO: move this logic to Deal model (in can() function)
  get canInspect() {
    const { section, variable, readonly } = this.props;

    if (readonly || (section && section.deleted)) return false;

    // PDF vars are always editable by owner, regardless of Deal status, because these vars are external metadata
    if (_.get(variable.deal, 'isOwner') && _.get(variable.deal, 'isExternal')) return true;

    const du = variable.deal.currentDealUser;

    switch (variable.type) {
      case VariableType.TERM:
        return true;
      //for simple vars, inspection means editing
      //only allow inspection if:
      //1. deal is NOT locked, and either
      //2. current user is editor/owner of the contract, OR
      //2. the variable is assigned to party that current user is in
      case VariableType.SIMPLE:
      case VariableType.CONNECTED:
      case VariableType.FOOTNOTE:
        if (variable.deal.locked || !du) return false;
        return (
          [DealRole.EDITOR, DealRole.OWNER].indexOf(du.role) > -1 ||
          (variable.baseVariable.assigned && variable.baseVariable.assigned == du.partyID)
        );

      //protected vars can be inspected even after deal is locked, so that condition is removed
      //they can be seen only by owners and by all parties
      case VariableType.PROTECTED:
        if (!du) return false;
        //disallow inspection of a read-only variable that hasn't been filled yet
        if (!variable.val && !this.canEdit) return false;
        //this is slightly out of sync with the server-side logic, but this is intentional
        //non-assigned party users and owners will be able to see value once deal is signed
        //before signing, API throws a 403 error which is handled above (see revealProtected)
        //and the box displays a masked value
        return du.role === DealRole.OWNER || du.partyID != null;
      default:
        return false;
    }
  }

  get canEdit() {
    const { section, variable, readonly } = this.props;

    if (readonly || (section && section.deleted)) return false;

    switch (variable.type) {
      //for simple vars, inspection and editing are the same thing
      case VariableType.SIMPLE:
      case VariableType.CONNECTED:
      case VariableType.FOOTNOTE:
        return this.canInspect;
      //protected vars can still be edited after deal is locked,
      //and only by editors/owners and owners
      case VariableType.PROTECTED:
        const du = variable.deal.currentDealUser;
        if (!du) return false;

        //if the variable is assigned to this party, they can edit
        //otherwise it's inspect-only
        if (variable.assigned != null) return variable.assigned == du.partyID;
        //if unassigned, variable can be edited by any party or owner
        else return du.role == DealRole.OWNER || du.partyID != null;
      default:
        return false;
    }
  }

  get calculatedTip() {
    const { variable } = this.props;

    // This is a tad roundabout but if Variable.calculate() results in an error,
    // the full error object is handled internally in Variable.val and Variable.isBroken getters
    // but if we see that both of those have triggered, we can do a reverse lookup based on the errorValue, which is unique
    if ((variable.type === VariableType.CALCULATED || variable.isTableTotal) && variable.isBroken) {
      if (!variable.formula) return 'Formula empty';
      const errorValue = variable.val;
      const err = _.find(CALC_ERRORS, { errorValue });
      if (err) return err.message;
    }

    // Normal case with no calculation error, just show a tooltip indicating read-only
    return 'Calculated (read-only)';
  }

  // TERM (definition) and REF variables cannot be edited in VariableView, only in Draft
  // And STATE and LIST variables don't need a button because they're rendered as DropddownButtons
  get showActions() {
    const { variable } = this.props;
    const { type, valueType, connectType, autoPull, autoPush } = variable;
    return (
      ![VariableType.TERM, VariableType.REF].includes(type) &&
      ![ValueType.STATE, ValueType.LIST].includes(valueType) &&
      !variable.deal.locked &&
      (!connectType || !autoPull || autoPush)
    );
  }

  // Variable.autoPull setting is only available for connected vars
  // And only togglable if deal is not locked
  get showAutoSync() {
    const { variable } = this.props;
    return !!variable.baseVariable.isConnected && !variable.deal.locked;
  }

  get hideConnectedSyncOptions() {
    const { variable } = this.props;
    const du = variable.deal.currentDealUser;
    if (du?.role != DealRole.OWNER && du?.role != DealRole.EDITOR) {
      if (variable.assigned != null) {
        if (variable.assigned == du?.partyID) {
          return true;
        }
      }
    }
    return false;
  }

  //quick link handler to populate a date variable with today's date
  async useToday() {
    const value = DateFormatter.mdy(new Date());
    await this.setState({ value });
    this.focus();
  }

  //Images inline in contract have an "Update" button to commit (save) the image
  //but there is no button in inline mode (ElementsView panel) so auto save on upload/clear
  //we may want to have this just be the behavior in both spots -- saves a click!
  async onImage(imageAttachment) {
    const { inline } = this.props;

    if (inline) {
      if (!imageAttachment) {
        await this.removeImageAttachment();
        this.setState({ value: '' });
      } else {
        const imageValue = await this.createImageAttachment(imageAttachment);
        this.setState({ value: imageValue });
      }
      this.save(true);
    } else {
      if (!imageAttachment) {
        this.setState({ value: '', imageAttachment });
      } else {
        //we want to be able to preview the image prior to actually saving it.
        const base64 = Buffer.from(imageAttachment.attachmentBytes).toString('base64');
        const encodedBase64 = `data:image/${imageAttachment.attachment.extension};base64,${base64}`;
        this.setState({ value: encodedBase64, imageAttachment });
      }
    }
  }

  async removeImageAttachment() {
    const { variable, deal } = this.props;
    const { value } = variable;

    //only remove the attachment if its not legacy base64
    if (typeof value === 'object') {
      const imageAttachment = _.find(deal.attachments, { key: value.key });
      await Fire.deleteAttachment(imageAttachment);
    }
  }

  async createImageAttachment(imageAttachment) {
    const { deal } = this.props;
    const { attachment, attachmentBytes } = imageAttachment;
    const updatedAttachment = await Fire.saveAttachment(attachment, attachmentBytes);
    await API.call('syncDealPermissions', { dealID: deal.dealID });
    const { key, bucketPath } = updatedAttachment;
    const downloadURL = await Fire.storage.ref(bucketPath).getDownloadURL();
    return { key, downloadURL };
  }

  renderTodayLink() {
    const { variable } = this.props;
    const { isValid } = this.state;
    const isDate =
      variable?.baseVariable?.type == VariableType.SIMPLE && variable?.baseVariable?.valueType == ValueType.DATE;

    if (!isDate) return;

    return (
      <>
        {!isValid && <div className="invalid-date-msg">Invalid date</div>}
        <a className="today-link" onClick={this.useToday} data-cy="today-link">
          Today
        </a>
      </>
    );
  }

  updateDate(date) {
    const { dateValue } = this.state;

    let value = '';
    if (date?.date) {
      const selectedDate = date.date;
      // value = moment(selectedDate).format('MM/DD/YYYY');
      value = `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`;
    } else {
      value = dateValue;
    }

    const isValidDate = this.validateDate(value);
    // if (!value.match(/\d{4}$/) && value !== '') {
    //   const currentYear = new Date().getFullYear();
    //   this.setState({ value: `${value}/${currentYear}` });
    // }

    console.log('Check if the date value is valid: ', { value: value, isValid: isValidDate });
    // this.setState({ value: value, isValid: isValidDate });
    this.setState({ value: value, isValid: this.validateDate(value) });
  }

  renderDatePicker() {
    const { value, isValid } = this.state;

    return (
      <div className={cx('day-time-picker', { 'invalid-date': !isValid })}>
        <DayTime
          type="text"
          bsSize="small"
          onChangeValue={(dateValue) => this.setState({ dateValue })}
          onChange={this.updateDate}
          date={value ? new Date(value) : null}
          hasDateTo={false}
          readOnly={false}
          hasTime={false}
          showOverlay={true}
          hideOnClick={false}
          // placeholder="mm/dd/yyyy"
          placeholder="MM/DD/YYYY"
          format={'MM/DD/YYYY'}
        />
        {!isValid && (
          <div className="invalid-date-msg">
            <p>Invalid date</p>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      noReplace,
      container,
      readonly,
      section,
      text,
      inline,
      variable,
      style,
      markReview,
      editExtractedValue,
      onCancel,
    } = this.props;
    const { loading, show, isValid, value } = this.state;
    const deal = section.deal;
    //disable update if the variable value hasnt changed. this will reference the wrong person in the audit log.
    let updateDisabled = true;
    let fontSize;

    if (!variable) {
      if (text[1] === VariableType.PAGE_COUNT) {
        const sourceSection = section.isTemplateHeaderFooterSubSection ? section.sourceParent : section;

        const display = sourceSection.headerFooterNumbering.format('#', '#');
        return <span>{display}</span>;
      }
      return <span className={`variable-defining`}>[{text.replace(rxVariableReplace, '')}]</span>;
    }

    if (variable.value || (!variable.value && value !== '')) {
      if (variable.valueType === ValueType.MULTI_SELECT) {
        updateDisabled = value === variable.baseVariable.multiSelectedOptions;
      } else {
        updateDisabled = variable.value === value;
      }
    }

    //TERM variables are unique in that they can always be shown and never edited (they are defined only in template editor)
    //otherwise, the popover is showable if current user has edit rights,
    //variable is of an editable type, and is not accessing a property of another variable (i.e., no . in name)
    const canInspect = this.canInspect && !noReplace;
    const canEdit = this.canEdit;
    const isNotEditableDisplay = !canEdit && !canInspect;
    const isProtected = variable.type == VariableType.PROTECTED;
    const isFootnote = variable.type == VariableType.FOOTNOTE;
    const isCalculated = variable.type === VariableType.CALCULATED || variable.isTableTotal;
    const isDate =
      variable?.baseVariable?.type == VariableType.SIMPLE && variable?.baseVariable?.valueType == ValueType.DATE;

    //always set updateDisabled to false when its a secret variable.
    //The encyrpted values stay the same until the update occurs making variable.value === value always true.
    if (isProtected) {
      updateDisabled = false;
    }

    const className = cx(
      'variable',
      variable.displayType,
      { 'not-editable': isNotEditableDisplay },
      { complete: !isNotEditableDisplay && !!variable.val },
      { incomplete: !isNotEditableDisplay && !variable.val },
      { locked: deal.locked && !isProtected },
      { readonly: readonly },
      { attachment: variable.valueType == ValueType.IMAGE },
      { broken: variable.isBroken },
      { calculated: isCalculated }
    );

    const { property: subProp } = variable.isDerived
      ? variable.getPropertyAndTextTransform(variable?.name?.split('.'))
      : {};

    //do variable replacement for non-TERM type variables, if a value is present
    //TERM variables use the displayName as display (value is only for reference)
    const title =
      subProp && subProp !== 'none'
        ? `${variable.baseVariable.displayName || variable.name} (${subProp})`
        : `${variable.baseVariable.displayName || variable.name}`;
    const popTitle = isFootnote
      ? `Footnote (${variable.ftNumber})`
      : `${variable.baseVariable.displayName || variable.baseVariable.name}`;
    let displayText = !noReplace && variable.val && variable.type != VariableType.TERM ? variable.val : title;

    if (variable && variable.type == VariableType.FOOTNOTE) {
      //get the masked value for the footnote in order
      const footnotes = section.footnotesFromText;
      //we need to make the index check to properly sort
      const footnoteNumber = _.findIndex(footnotes, (footnote) => {
        return footnote === variable.name;
      });

      displayText = formatOrder(footnoteNumber, deal.footnoteConfig.numberFormat);
    }

    if (variable.val && variable.type == VariableType.SIMPLE && variable.valueType == ValueType.IMAGE) {
      displayText = <img src={displayText} onLoad={this.onImageLoaded} alt={title} />;
    }

    if (
      variable &&
      variable.valueType === ValueType.MULTI_SELECT &&
      variable.baseVariable.multiSelectedOptions.length > 0
    ) {
      displayText = _.map(variable.baseVariable.multiSelectedOptions, 'value').join(variable.baseVariable.seperator);
    }

    // Support multiple consecutive spaces and line breaks in Variable values
    if (variable?.baseVariable?.multiline || variable.multilineValueOptions?.length > 1) {
      if (typeof displayText === 'string') {
        displayText = displayText?.split('\n').join(variable.baseVariable.seperator);
      } else if (Array.isArray(displayText)) {
        displayText = displayText.join(variable.baseVariable.seperator);
      }
    }

    if (typeof displayText === 'string') {
      displayText = <Breakable>{displayText}</Breakable>;
    }

    if (variable.type == VariableType.FOOTNOTE) {
      fontSize = section.styleBody.native.size * FOOTNOTE_FONT_RATIO;
    }

    if (markReview || editExtractedValue) {
      const disable = !value ? !variable.value && !value : variable.value === value;

      return (
        <div
          className={cx('variable-view', { 'manual-review': markReview, 'edit-extracted': editExtractedValue })}
          data-variable={variable.name}
          onMouseDown={this.stop}
          onMouseUp={this.stop}
          style={{ ...style, fontSize }}
        >
          {this.renderEditor(!canEdit || _.get(variable, 'isControlled'))}
          {this.renderDSBrowser()}
          <Button
            className="mark-reviewed"
            size="small"
            onClick={() => this.save(false)}
            dmpStyle="link"
            data-cy="btn-save-variable"
            disabled={editExtractedValue && disable}
          >
            {markReview ? 'Mark reviewed' : 'Save'}
          </Button>
          {editExtractedValue && (
            <Button className="cancel-edit" size="small" onClick={onCancel} dmpStyle="link" data-cy="btn-cancel">
              Cancel
            </Button>
          )}
        </div>
      );
    }

    if (inline) {
      return (
        <div
          className="variable-view"
          data-variable={variable.name}
          onMouseDown={this.stop}
          onMouseUp={this.stop}
          style={{ ...style, fontSize }}
        >
          {this.renderEditor(!canEdit || _.get(variable, 'isControlled'))}
          {canEdit && this.renderTodayLink()}
          {/* {canEdit && this.renderDatePicker()} */}
          {this.renderDSBrowser()}
        </div>
      );
    }

    // Wrap calculated vars in a tooltip showing read-only
    // (normal vars will not have a tooltip; disabled=true on TooltipButton renders inner node)
    return (
      <>
        <TooltipButton tip={this.calculatedTip} placement="bottom" disabled={!isCalculated}>
          <span
            ref={this.textRef}
            onClick={(e) => this.show(e, canInspect)}
            className={className}
            data-variable={variable.name}
            onMouseDown={this.stop}
            onMouseUp={this.stop}
            style={{ ...style, fontSize }}
          >
            {displayText}
          </span>
        </TooltipButton>
        <Overlay
          container={container}
          onHide={this.hide}
          placement="bottom"
          rootClose
          show={show}
          target={this.textRef.current}
        >
          <Popover
            id={`${this.id}-pop`}
            onClick={(event) => event.stopPropagation()}
            onMouseDown={this.stop}
            onMouseUp={this.stop}
            title={popTitle}
            className={cx('popover-variable-view', { 'date-popover-variable-view': isDate })}
            // className="popover-variable-view"
            data-cy="popover-variable-view"
            closeBtn={show}
            onHide={this.hide}
          >
            <Form>
              {this.showAutoSync && (
                <>
                  <VariableConnector
                    variable={variable.baseVariable}
                    id={`${this.id}-var-connector`}
                    onUpdate={(variable) => this.populate({ variable })}
                    onBrowseDS={() => this.setState({ browseDS: true, show: false })}
                    hideConnectedSyncOptions={this.hideConnectedSyncOptions}
                    disablePush={variable.isRedacted}
                  />
                  {!variable.isBroken && <div className="control-label">Value</div>}
                </>
              )}
              {this.renderEditor()}
              {variable.isDerived && variable.textTransform?.data !== 'none' && (
                <div>
                  <small>{`Displaying as ${variable.textTransform?.data}`}</small>
                </div>
              )}
              {/* {canEdit && this.renderTodayLink()} */}
              {canEdit && isDate && this.renderDatePicker()}
            </Form>

            {this.showActions && (
              <div className={cx('actions', { 'date-actions': isDate })}>
                {loading && <Loader />}
                <div className="spacer" />
                {canEdit && (
                  <>
                    <Button className="cancel" size="small" onClick={this.hide} data-cy="btn-cancel">
                      Cancel
                    </Button>
                    <Button
                      className="save"
                      disabled={!isValid || loading || updateDisabled}
                      size="small"
                      onClick={this.save}
                      dmpStyle="primary"
                      data-cy="btn-save-variable"
                    >
                      Update
                    </Button>
                  </>
                )}
              </div>
            )}
          </Popover>
        </Overlay>
        {this.renderDSBrowser()}
      </>
    );
  }

  renderDSBrowser() {
    const { variable } = this.props;
    const { browseDS } = this.state;

    if (!browseDS) return null;

    return (
      <DataSourceBrowser
        deal={variable.deal}
        show={browseDS}
        variable={variable.isDerived ? variable.baseVariable : variable}
        onHide={() => this.setState({ browseDS: false })}
        onSelect={this.handleDSItems}
      />
    );
  }

  renderEditor(readOnly) {
    const { inline, variable, deal } = this.props;
    const { value, isValid } = this.state;
    const { service, autoPush, isControlled, multiline, multilineValueOptions, isRedacted } = variable?.baseVariable;
    const multilineValueLabels = variable.baseVariable?.multilineValueLabelsMap;

    const canClear = !readOnly && !!variable.value;

    let pushWarning = null;
    if (service && autoPush && value !== variable.value) {
      pushWarning = <div className="warning">Updating will overwrite the value on both Outlaw and {service.name}.</div>;
    }

    if (
      (variable.type === VariableType.CONNECTED && variable.isBroken) ||
      (variable.valueType === ValueType.IMAGE && variable.isRedacted)
    )
      return null;

    switch (variable.type) {
      case VariableType.REF:
        //variable.value is the actual section ID of the referenced section
        //so we can attempt to show a title for it if it's there
        const ref = variable.value ? variable.deal.sections[variable.value] : null;
        if (ref) return ref.displayname || 'Untitled section';
        else return 'Section not found';

      case VariableType.SIMPLE:
      case VariableType.CONNECTED:
        switch (variable?.baseVariable?.valueType) {
          case ValueType.STATE:
            return (
              <FormGroup>
                <Dropdown
                  id={`dd-state-${variable.name}`}
                  disabled={readOnly || isControlled}
                  title={value ? States[value] || value : variable?.baseVariable?.prompt || 'Select State'}
                  onSelect={this.selectListItem}
                  size="small"
                  block
                  dataCyToggle="dd-state"
                >
                  {StatesList.map((st) => (
                    <MenuItem key={st.key} eventKey={st.key} active={st.key === value}>
                      {st.name}
                    </MenuItem>
                  ))}
                  {canClear && <MenuItem divider />}
                  {canClear && (
                    <MenuItem className="clear-selection" eventKey={null}>
                      Clear Selection
                    </MenuItem>
                  )}
                </Dropdown>
              </FormGroup>
            );
          case ValueType.LIST:
            return (
              <FormGroup>
                <Dropdown
                  id={`dd-list-${variable.name}`}
                  disabled={readOnly || isControlled}
                  title={value || 'Select One'}
                  onSelect={this.selectListItem}
                  size="small"
                  dataCyToggle="dd-list"
                  block
                >
                  {variable?.baseVariable?.options.map((opt, idx) => (
                    <MenuItem key={idx} eventKey={opt.key} active={opt.key === value}>
                      {opt.title}
                    </MenuItem>
                  ))}
                  {canClear && <MenuItem divider />}
                  {canClear && (
                    <MenuItem className="clear-selection" eventKey={null}>
                      Clear Selection
                    </MenuItem>
                  )}
                </Dropdown>
                {pushWarning}
              </FormGroup>
            );
          case ValueType.MULTI_SELECT:
            return (
              <FormGroup>
                <MultiselectDropdown
                  onChange={(value) => this.setState({ value })}
                  options={variable?.baseVariable?.options}
                  defaultValue={value || variable.baseVariable.multiSelectedOptions}
                  onMenuClose={() => (inline ? this.save() : null)}
                />
              </FormGroup>
            );
          case ValueType.IMAGE:
            return (
              <FormGroup>
                <ImageUploader
                  image={value ? value : null}
                  onImage={this.onImage}
                  disabled={readOnly || isControlled}
                  deal={deal}
                />
              </FormGroup>
            );
          case ValueType.STRING:
          default:
            if (variable?.baseVariable?.type === VariableType.CONNECTED) {
              const valuesList =
                multiline && !multilineValueOptions
                  ? Array.isArray(variable?.baseVariable?.value)
                    ? variable?.baseVariable?.value
                    : variable?.baseVariable?.value?.split('\n')
                  : multilineValueOptions;
              return (
                <FormGroup className={variable?.baseVariable?.hasValidation ? 'dmp-validator-container' : null}>
                  {multiline && valuesList ? (
                    <div
                      className={`variable-multiple-value form-control ${readOnly || isControlled ? 'disabled' : ''}`}
                    >
                      {valuesList?.length > 1 && (
                        <Radio
                          checked={value?.split('\n').length > 1}
                          name="displayAll"
                          onChange={this.selectMultilineValue}
                          value={valuesList.join('\n')}
                          disabled={readOnly || isControlled}
                        >
                          {'Display all (comma separated)'}
                        </Radio>
                      )}
                      {valuesList.map((val) => {
                        return (
                          <Radio
                            key={val}
                            checked={val === value}
                            name={val}
                            onChange={this.selectMultilineValue}
                            value={val}
                            disabled={readOnly || isControlled}
                          >
                            {val}
                            {multilineValueLabels && multilineValueLabels[val] && (
                              <span className="multi-line-label">{` (${multilineValueLabels[val]})`}</span>
                            )}
                          </Radio>
                        );
                      })}
                    </div>
                  ) : (
                    <FormControl
                      className="variable-value"
                      componentClass={multiline ? 'textarea' : 'input'}
                      disabled={readOnly || isControlled || isRedacted}
                      inputRef={(ref) => (this.inputRef = ref)}
                      onBlur={() => (inline ? this.save(true) : null)}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyCommand}
                      placeholder={variable?.baseVariable?.prompt}
                      type="text"
                      value={value}
                      bsSize="small"
                      data-cy="variable-value"
                    />
                  )}
                  {variable?.baseVariable?.hasValidation && (
                    <Validator
                      value={value}
                      validate={this.validate}
                      onResult={(isValid) => this.setState({ isValid })}
                      debounce={100}
                      invalidTip="Not a valid number value."
                    />
                  )}
                  {(readOnly || isControlled || isRedacted) && <small>{'Value is read-only (pull sync)'}</small>}
                  {pushWarning}
                </FormGroup>
              );
            } else if (variable?.baseVariable?.valueType == ValueType.DATE) {
              return (
                <>
                  <FormGroup className={!isValid ? 'invalid-date' : null}>
                    {inline && (
                      <FormControl
                        className="variable-value"
                        componentClass={multiline ? 'textarea' : 'input'}
                        disabled={readOnly || isControlled}
                        inputRef={(ref) => (this.inputRef = ref)}
                        onBlur={() => (inline && isValid ? this.save(true) : null)} // Check isValid before saving
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyCommand}
                        placeholder={variable?.baseVariable?.prompt}
                        type="text"
                        value={value}
                        bsSize="small"
                        data-cy="variable-value"
                      />
                    )}
                  </FormGroup>
                </>
              );
            } else {
              return (
                <FormGroup className={variable?.baseVariable?.hasValidation ? 'dmp-validator-container' : null}>
                  <FormControl
                    className="variable-value"
                    componentClass={multiline ? 'textarea' : 'input'}
                    disabled={readOnly || isControlled}
                    inputRef={(ref) => (this.inputRef = ref)}
                    onBlur={() => (inline ? this.save(true) : null)}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyCommand}
                    placeholder={variable?.baseVariable?.prompt}
                    type="text"
                    value={value}
                    bsSize="small"
                    data-cy="variable-value"
                  />
                  {variable.hasValidation && (
                    <Validator
                      value={value}
                      validate={this.validate}
                      onResult={(isValid) => this.setState({ isValid })}
                      debounce={100}
                      invalidTip="Not a valid number value."
                    />
                  )}
                  {pushWarning}
                </FormGroup>
              );
            }
        }
      case VariableType.FOOTNOTE:
        return (
          <FormGroup>
            <FormControl
              className="variable-value"
              componentClass="textarea"
              disabled={readOnly || isControlled}
              inputRef={(ref) => (this.inputRef = ref)}
              onBlur={() => (inline ? this.save(true) : null)}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyCommand}
              placeholder={variable.baseVariable.prompt}
              type="text"
              value={value}
              bsSize="small"
              data-cy="variable-value"
            />
          </FormGroup>
        );
      case VariableType.PROTECTED:
        const { loading, decrypted } = this.state;

        let note = 'This field is securely encrypted. ',
          p = null;
        if (variable.assigned) p = variable.deal.getPartyByID(variable.assigned);
        if (p)
          note += `It is only editable by the ${p.displayName} and only viewable by other Parties once this ${dt} is fully signed.`;
        else note += `It is only viewable and editable by Parties on this ${dt}.`;

        return (
          <FormGroup className={`protected ${this.canEdit ? 'editable' : 'readonly'}`}>
            <FormControl
              disabled={readOnly || loading}
              inputRef={(ref) => (this.inputRef = ref)}
              onChange={(e) => this.setState({ decrypted: e.target.value })}
              onKeyDown={this.handleKeyCommand}
              placeholder={variable.prompt}
              type="text"
              value={decrypted}
              bsSize="small"
            />
            <Icon name="secret" />
            <span className="note">{note}</span>
          </FormGroup>
        );
      case VariableType.TERM:
      case VariableType.REF:
        return variable.val;
      default:
        return null;
    }
  }
}

export default VariableView;
